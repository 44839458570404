import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components}>
    <h1 {...{
      "id": "projects-using-mdx"
    }}>{`Projects using MDX`}</h1>
    <h2 {...{
      "id": "apps"
    }}>{`Apps`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://frontarm.com/demoboard"
        }}>{`demoboard`}</a>{`: The simplest editor alive`}</li>
    </ul>
    <h2 {...{
      "id": "libraries"
    }}>{`Libraries`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/jxnblk/ok-mdx"
        }}>{`ok-mdx`}</a>{`: Browser-based MDX editor`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.docz.site/"
        }}>{`docz`}</a>{`: Documentation framework`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/jxnblk/mdx-deck"
        }}>{`mdx-deck`}</a>{`: MDX-based presentation decks`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/jxnblk/mdx-docs"
        }}>{`mdx-docs`}</a>{`: Next-based documentation framework`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/FormidableLabs/spectacle-boilerplate-mdx"
        }}>{`spectacle-boilerplate-mdx`}</a>{`: Boilerplate that facilitates using MDX with
Spectacle`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://charge.js.org"
        }}>{`Charge`}</a>{`: An opinionated, zero-config static site generator`}</li>
    </ul>
    <h2 {...{
      "id": "sites"
    }}>{`Sites`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/zeit/docs"
        }}>{`ZEIT Docs`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://compositor.io"
        }}>{`Compositor`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.prisma.io/docs"
        }}>{`Prisma`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://mxstbr.com"
        }}>{`Max Stoiber’s Blog`}</a></li>
    </ul>
    <h2 {...{
      "id": "other-related-links"
    }}>{`Other related links`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/transitive-bullshit/awesome-mdx"
        }}>{`awesome-mdx`}</a></li>
    </ul>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      